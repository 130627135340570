/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import CssBaseline from '@material-ui/core/CssBaseline';

import Header from './header';
import './layout.css';

const Layout = ({ children, noheader = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        # buildTime(locale: "fr", fromNow: false, formatString: "dddd Do MMMM YYYY")
        siteMetadata { title }
      }
    }
  `);

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
      <CssBaseline />
      {!noheader && <Header siteTitle={data.site.siteMetadata.title} />}

      <div
        style={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '0px 1.0875rem 1.45rem',
          paddingTop: (!noheader ? 100 : '2em'),
        }}
      >
        <main>{children}</main>
        <footer style={{ paddingTop: '3em', textAlign: 'center' }} />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
